.sidebar-button {
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 5px;
  margin: 5px;
}
.sidebar-button-container {
  /* width: 100%; */
  color: white;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 0px;
  margin: 5px 15px;
  margin-right: 15px;
  cursor: pointer;
}

.sidebar .sidebar-button-container > p {
  font-size: 25px;
  color: inherit;
  padding: 0px;
  padding-left: 10px;
  padding-right: 20px;
  padding-bottom: 2px;
  margin: 0px;
}
.sidebar-button-container:hover {
  color: var(--highlight-color-primary);
}
.sidebar-button-container.active {
  background-color: var(--highlight-color-primary) !important;
  color: white;
  border-radius: 5px;
}
.sidebar-button-container:hover .sidebar-button {
  /* color: white; */
  /* background-color: var(--highlight-color-primary); */
  border-radius: 5px;
}
