.recpie-edit-add-button {
  background-color: grey;
  margin-top: 10px;
  color: white;
}
.recpie-edit-delete-button {
  background-color: indianred;
  margin-right: 7px;
  margin-top: 10px;
  color: white;
}
