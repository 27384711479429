#recipes {
  flex: 1;
  width: auto;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px 10px;
  position: relative;
}
#recipes-search-box {
  background-color: var(--background-color-secondary);
  margin: 0px;
  padding: 5px;
}

.my-masonry-grid {
  display: flex;
  flex: 1;
  width: auto;
  height: auto;
}
.my-masonry-grid_column {
  background-clip: padding-box;
  padding: 0px 5px;
  padding-bottom: 5px;
}

/* Style your items */
/* .my-masonry-grid_column > div {
  background: grey;
} */

.recipes-card-container {
  margin: 10px 0px;
  margin-bottom: 0px;
  margin-right: 0px;
}

@media screen and (max-width: 600px) {
  .recipes-container {
    max-height: none;
  }
  .import-recipe-button {
    margin-bottom: 60px;
  }
}
