.recipe {
  flex: 1;
  width: auto;
}

.fill {
  flex: 1;
}

#one {
  background-color: steelblue;
  flex-direction: column;
}

.img-text {
  display: block;
  position: absolute;
  bottom: 8px;
  left: 16px;
}
