.tag-label {
  width: fit-content;
  background-color: gray;
  border-radius: 20px;
  flex-direction: row;
  height: min-content;
  padding-left: 10px;
  padding-right: 5px;
  padding-bottom: 0.2%;
  align-items: center;
  justify-content: center;
}
.tag-label p {
  color: rgb(34, 34, 34);
  font-size: 15px;
  padding: 0px;
  margin: 0px;
  width: max-content;
}
