#recipe-left-scroll-container {
  overflow: auto;
  width: 100%;
}

@media screen and (max-width: 600px) {
  #recipe .floating-button-container {
    position: fixed;
    bottom: 60px;
  }
}
