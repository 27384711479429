.recipe-title,
.recipe-title-edit,
.recipe-description {
  width: 100%;
  color: white;
  border-radius: inherit;
}
.recipe-title,
.recipe-title-edit {
  margin: 0px;
  background-image: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1),
    rgba(34, 34, 34, 0)
  );
  z-index: 1;
  margin-top: auto;
  padding-top: 30px;
}
.recipe-title h1 {
  padding: 30px 10px;
  margin: 0px;
  text-align: center;
}

.recipe-description {
  width: 100%;
  overflow-wrap: break-word;
  font-weight: normal;
  color: white;
  z-index: 1;
  border-radius: inherit;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  padding-bottom: 10x;
}
.recipe-description p {
  padding: 0px 10px;
  margin: 10px 0px;
  margin-top: 0px;
  padding-top: 10px;
  text-align: center;
}

.recipe-author p {
  text-align: center;
  color: white;
  font-size: 13px;
  font-style: italic;
  padding: 0px 10px;
}

.recipe-author a {
  text-align: center;
  color: #bbb;
  font-size: 15px;
}
