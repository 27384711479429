.searchbar {
  /* border: 1px solid var(--background-color-primary); */
  background-color: var(--background-color-primary);
  border-radius: 3px;
  padding: 10px;
  display: flex;
  align-items: center;
  color: white;
}
.searchbar > span {
  width: 100%;
  display: block;
  overflow: hidden;
  padding-left: 10px;
}
.searchbar > span > input {
  color: white;
  background-color: inherit;
  border: none;
  border-bottom: 1px solid white;
  width: 100%;
}
.searchbar > svg {
  float: left;
}

.searchbar > span > input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ccc;
  opacity: 0.8; /* Firefox */
}
