div.modal {
  display: hidden;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #00000088;
  z-index: 300;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.modal .modal-popup {
  display: block;
  margin: 0px;
  padding: 0px;
  background-color: black;
  width: 90%;
  max-width: 1200px;
  max-height: 90%;
  overflow: hidden;
  border-radius: 10px;
}

.modal .modal-popup .header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  height: 50px;
  padding-right: 16px;
}

.modal .modal-popup .header p {
  font-size: 25px;
}
.modal .modal-popup .header .icon-button-rounded {
  margin-left: auto;
  color: white;
}
.modal .modal-popup .header p {
  margin: 0px;
  padding: 0px;
  color: white;
  padding-left: 20px;
}

.modal .modal-popup .content {
  max-height: 80vh;
  height: 100%;
  position: relative;
  overflow: hidden;
  padding: 20px 30px;
  color: white;
  overflow-y: auto;
  margin-bottom: 10px;
}
.modal .modal-popup .content p {
  padding: 0px 30px;
  margin: 0px;
  margin-left: auto;
  text-align: center;
}
.modal .modal-popup .content h1 {
  padding-bottom: 20px;
}
.modal .modal-popup .content img {
  object-fit: contain;
  height: 30vw;
  max-width: 700px;
  max-height: 250px;
}
.modal .modal-popup .content div.double img {
  height: 18vw;
  max-height: 250px;
}

.modal .modal-popup .content .footer {
  display: flex;
  margin: auto;
  justify-content: center;
}

.modal .modal-popup .content .footer button {
  max-width: 100px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 1000px) {
  .modal .modal-popup .content div {
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
  }
  .modal .modal-popup .content img {
    /* margin: auto; */
    margin-bottom: 20px;
  }
  .modal .modal-popup .content div.double img {
    height: 30vw;
    margin: auto;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 600px) {
  .modal .modal-popup .content div {
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    padding: 20px 0px !important;
  }
  .modal .modal-popup .content .footer {
    display: flex !important;
    justify-content: center;
  }
  .modal .modal-popup .content {
    padding: 20px 20px;
  }
  .modal .modal-popup .content p {
    padding: 0px;
    margin: 0px;
  }
  .modal .modal-popup .content img {
    height: 30vw;
    margin: auto;
    margin-bottom: 20px;
  }
  .modal .modal-popup .content div.double img {
    height: 30vw;
    margin: auto;
    margin-bottom: 20px;
  }
}
