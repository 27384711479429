.instruction-edit-card {
  border-radius: inherit;
  min-height: 100px;
  flex: 1;
  margin: 0px;
  padding: 5px;
  color: white;
  background-color: var(--background-color-primary);
}
.instruction-edit-card .controls {
  padding: 5px;
  justify-content: center;
  align-items: center;
}
.instruction-edit-card .controls .icon-button-rounded {
  margin: 0px 5px;
}

.instruction-edit-card input,
.instruction-edit-card textarea {
  background-color: transparent;
  resize: none;
  color: white;
  border-style: solid;
  border-width: 1px;
  font-family: inherit;
}
.instruction-edit-card input {
  font-size: 20px;
  padding: 5px;
  margin-bottom: 5px;
}
.instruction-edit-card textarea {
  padding: 5px;
}

/* AUTOCOMPLETE */
.rta {
  position: relative;
  font-size: 18px;
  width: 100%;
  height: 100%;
}
.rta__loader.rta__loader--empty-suggestion-data {
  border-radius: 3px;
  box-shadow: 0 0 5px rgba(27, 31, 35, 0.1);
  padding: 5px;
}
.rta--loading .rta__loader.rta__loader--suggestion-data {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
}
.rta--loading .rta__loader.rta__loader--suggestion-data > * {
  position: relative;
  top: 50%;
}
.rta__textarea {
  width: 100%;
  height: 100%;
  background-color: transparent;
  color: white;
  border-style: solid;
  border-width: 1px;
  font-family: inherit;
  font-size: 15px;
}
.rta__autocomplete {
  position: absolute;
  display: block;
  margin-top: 1.2em;
}
.rta__autocomplete--top {
  margin-top: 0;
  margin-bottom: 1em;
}
.rta__list {
  font-size: 15px;
  margin: 0;
  padding: 0;
  background: var(--background-color-primary);
  color: #bbb;
  border: 1px solid var(--background-color-primary);
  border-radius: 3px;
  box-shadow: 0 0 5px rgba(27, 31, 35, 0.1);
  list-style: none;
  z-index: 10;
  max-width: 400px;
}
.rta__entity {
  background: var(--background-color-secondary);
  width: 100%;
  text-align: left;
  outline: none;
}
.rta__entity:hover {
  cursor: pointer;
}
.rta__item:not(:last-child) {
  border-bottom: 1px solid var(--background-color-primary);
}
.rta__entity > * {
  padding-left: 4px;
  padding-right: 4px;
}
.rta__entity--selected {
  color: #fff;
  text-decoration: none;
  background: var(--highlight-color-primary);
}
