.count-down-timer-container {
  padding: 5px;
  margin: 5px;
  position: relative;
  max-width: 100px;
}
.count-down-timer-close-button {
  position: absolute;
  right: 0px;
}
.count-down-timer-p {
  padding: 0px;
  margin: 0px;
  align-self: flex-start;
  word-wrap: break-word;
}

.blink {
  animation: blink-animation 0.5s steps(2, start);
  -webkit-animation: blink-animation 0.5s steps(2, start);
}
@keyframes blink-animation {
  to {
    visibility: visible;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: visible;
  }
}

.shake {
  animation: shake 0.8s;
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
