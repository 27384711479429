#settings {
  width: 100%;
  color: white;
}

#settings .settings-container {
  background-color: var(--background-color-primary);
  margin: 40px;
  padding: 10px;
  border-radius: 10px;
  min-height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
}
