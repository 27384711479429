.content {
  border-radius: inherit;
  min-height: 100px;
}

#instructions {
  flex: 1;
  margin: 0px;
  padding: 10px;
  color: white;
}

#instructions h2,
#instructions p {
  margin: 0px;
  padding: 0px;
}

[contenteditable]:focus {
  outline: 1px solid #ffffff80;
}

#instructions h2 {
  margin-bottom: 10px;
}

.instruction-title[contenteditable]:focus {
  font-style: inherit !important;
  opacity: inherit !important;
}

.recipe-tooltip {
  border-color: #99999960;
  border-style: solid;
  border-width: 1px;
  background-color: var(--background-color-primary);
  margin-bottom: 5px;
  border-radius: 10px;
  padding: 2px 7px;
  top: -10px;
}

.recipe-timer-row:not(:empty) {
  margin: 0px 10px;
  flex-wrap: wrap;
  justify-content: center;
  background-color: var(--background-color-primary);
  padding-bottom: 5px;
  border-top-style: dotted;
  border-top-width: 2.5px;
  border-top-color: var(--background-color-secondary);
  position: relative;
  border-radius: inherit;
  border-radius: 0 0 2px 2px;
}

.recipe-timer::before,
.recipe-timer::after {
  content: '';
  position: absolute;
  top: -5px;
  border-radius: 10px;
  height: 8px;
  width: 8px;
  background-color: var(--background-color-secondary);
}

.recipe-timer::before {
  right: -4px;
}

.recipe-timer::after {
  left: -4px;
}
