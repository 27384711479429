.input-modal {
  z-index: 10000;
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 0px;
  background-color: #000000e8;
}

.input-popup {
  width: 75%;
  max-width: 500px;
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  margin: auto;
}

.input-popup-button-container {
  justify-content: space-around;
}

.input-popup-button-container button {
  flex: 1;
}
