#user {
  width: 100%;
}

#user .user-container {
  background-color: var(--background-color-primary);
  margin: 40px;
  padding: 10px;
  border-radius: 10px;
  min-height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.user-data {
  padding: 10px;
  color: white;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  background-color: var(--background-color-primary);
  margin: 40px;
  padding: 10px;
  border-radius: 10px;
}
