.image-container {
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  border-radius: inherit;
  background-color: transparent;
}
.image-container-image {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  object-fit: cover;
  border-radius: inherit;
}
.image-text {
  display: flex;
  flex-wrap: wrap-reverse;
  flex-direction: row-reverse;
  justify-content: flex-end;
  width: 100%;
  padding-left: 2%;
  margin: 0px;
  padding-bottom: 10px;
  color: white;
}
.image-text > span {
  white-space: pre;
}

.image-container > h2:first-child {
  background-image: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.7),
    rgba(34, 34, 34, 0)
  );
  padding-top: 30px;
  z-index: 1;
  border-radius: inherit;
}
.image-container p {
  overflow-wrap: break-word;
  font-weight: normal;
  background-image: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.7)
  );
  padding-right: 0px;
  padding-bottom: 10px;
  z-index: 1;
  color: white;
  border-radius: inherit;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

@media screen and (max-width: 600px) {
  .image-container {
    /* position: sticky !important;
    top: 0; */
    min-height: 45vw;
  }
}
