.recipe-title input {
  margin: 30px 0px;
  font-size: 2rem;
}

.recipe-title input::placeholder,
.recipe-description input::placeholder {
  color: rgb(175, 175, 175);
  font-style: italic;
}

.recipe-title input,
.recipe-description textarea,
.recipe-description input {
  width: fill-available;
  background-color: transparent;
  resize: none;
  color: white;
  border-style: solid;
  border-width: 1px;
  border-color: white;
  padding-left: 5px;
  margin-left: 5px;
  margin-right: 5px;
}

.recipe-description textarea,
.recipe-description input {
  margin-top: 5px;
}
.recipe-description input:last-child {
  margin-bottom: 10px;
}
