.recipe-base-container {
  flex: 1;
  display: flex;
  width: auto;
}

.fill {
  flex: 1;
  flex-direction: column;
  overflow: auto;
  overflow-x: auto;
}

#right-container {
  padding-bottom: 10px;
  z-index: 0;
}

#right-container *:last-child {
  margin-bottom: 0px;
}

#left-container {
  position: relative;
  -webkit-box-shadow: 1px 7px 14px -8px rgb(0 0 0 / 100%);
  -moz-box-shadow: 1px 7px 14px -8px rgb(0 0 0 / 100%);
  box-shadow: 1px 7px 14px -8px rgb(0 0 0 / 100%);
  overflow: hidden;
  z-index: 1;
  max-width: 600px;
}

@media screen and (max-width: 600px) {
  .recipe-base-container {
    flex-direction: column;
    overflow-x: hidden;
    display: block !important;
  }
  #right-container {
    padding-top: 10px;
  }
  .fill {
    overflow: auto;
  }
}
