.floating-button-multi-container {
  z-index: 50;
  position: fixed;
  bottom: 0px;
  right: 0px;
  padding-right: 10px;
  padding-bottom: 10px;
  justify-content: center;
}
.floating-button-multi {
  background-color: var(--highlight-color-primary);
  color: white;
}
.sub-floating-button-multi {
  background-color: var(--highlight-color-primary-light);
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  color: black;
}
