.ingredients-list-edit input {
  background-color: #00000030;
  color: white;
  border-style: solid;
  border-width: 1px;
  font-family: inherit;
  padding: 5px;
  margin-bottom: 5px;
  flex: 1;
  margin-right: 10px;
}

.ingredients-list-edit .controls {
  height: max-content;
  color: white;
}
.ingredients-list-edit .controls .icon-button {
  background-color: inherit;
}
