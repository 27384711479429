/* width */
.sidebar ::-webkit-scrollbar {
  width: 0px;
}

.sidebar {
  background-color: var(--background-color-primary);
  /* width: 100px; */
  flex-direction: column;
  align-items: start;
  justify-content: start;
}
.sidebar-button-question {
  margin-top: auto;
}

.countdown-container {
  position: relative;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  overflow: auto;
  overflow-x: hidden;
  padding-bottom: 10px;
}
.sidebar .profil-image-container {
  margin: 10px auto;
}

.sidebar .profil-image:hover {
  border-color: var(--highlight-color-primary);
}

.sidebar .active .profil-image {
  border-color: var(--highlight-color-primary);
}

@media screen and (max-width: 600px) {
  .sidebar {
    flex-direction: row;
    justify-content: space-around;
    position: fixed;
    z-index: 100;
    bottom: 0px;
    left: 0px;
    right: 0px;
    height: 60px;
    align-items: center;
  }
  .sidebar-button-question {
    margin-top: inherit;
  }

  .sidebar-button-container:last-child {
    margin-top: 0;
  }
  .expand-button {
    display: none !important;
  }
  .countdown-container {
    display: none !important;
  }
  .sidebar .profil-image-container {
    margin: auto 10px !important;
  }
}
