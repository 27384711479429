#portion-bar {
  -webkit-box-shadow: 1px 7px 14px -8px rgb(0 0 0 / 100%);
  -moz-box-shadow: 1px 7px 14px -8px rgb(0 0 0 / 100%);
  box-shadow: 1px 7px 14px -8px rgb(0 0 0 / 100%);

  justify-content: space-between;
  color: white;
  align-items: center;
  margin: 10px;
  width: auto;
  border-radius: 100px;
  background-color: var(--background-color-primary);
  min-width: 200px;
}

#portion-bar p {
  margin: 0px;
}

@media screen and (max-width: 600px) {
}
