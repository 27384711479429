.tag-input {
  width: 100%;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.tag-input > span {
  width: 100%;
  display: block;
  overflow: hidden;
  /* padding-left: 10px; */
}
.tag-input > span > input {
  color: white;
  background-color: inherit;
  border: none;
  border-bottom: 1px solid white;
  width: 100%;
}
.tag-input > svg {
  float: left;
}

.tag-input > span > input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ccc;
  opacity: 0.8; /* Firefox */
}
