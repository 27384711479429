.crop-image-modal {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  background-color: #000000dd;
  z-index: 99;
  display: flex;
}
.crop-image-modal .inner-container {
  margin: auto;
  max-width: fit-content;
  background-color: var(--background-color-primary);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border-radius: 3px;
}
/* 
.ReactCrop__child-wrapper > img {
  margin: auto;
}
.ReactCrop {
} */
