.carousel {
  width: auto;
  height: 40vh;
  margin: auto;
}

.carousel-inner {
  height: inherit;
}

.carousel-item {
  height: inherit;
}
.carousel-indicators {
  margin-bottom: 0px;
}
.carousel-indicators [data-bs-target] {
  background-color: var(--background-color-secondary);
  opacity: 1;
}
.carousel-indicators .active {
  background-color: var(--highlight-color-primary);
}
.carousel-control-next,
.carousel-control-prev {
  bottom: 0px;
}
