:root {
  --background-color-primary: #2b2b2b;
  --background-color-secondary: #5a5a5a;
  /* --highlight-color-primary-light: #ec407a;
  --highlight-color-primary: #c2185b;
  --highlight-color-secondary: #e25c44;
  --highlight-color-third: #ffc302; */

  /* --highlight-color-primary-light: #f48449;
  --highlight-color-primary: #f2661c;
  --highlight-color-secondary: #e25c44;
  --highlight-color-third: #ffc302; */

  --highlight-color-primary-light: #339999;
  --highlight-color-primary: teal;
  --highlight-color-third: #ffc302;
  position: fixed;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #777;
}

* {
  /* background-color: inherit; */
  outline: none;
}

body {
  /*disable pull down refresh*/
  overscroll-behavior-y: contain;
  margin: 0;
  width: 100vw;
  height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color-secondary);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.shadow-box {
  -webkit-box-shadow: 0px 5px 14px -10px rgb(0 0 0 / 100%);
  -moz-box-shadow: 0px 5px 14px -10px rgb(0 0 0 / 100%);
  box-shadow: 0px 5px 14px -10px rgb(0 0 0 / 100%);
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-column-reverse {
  display: flex;
  flex-direction: column-reverse;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.clickable {
  cursor: pointer;
}

.clickable:hover {
  filter: brightness(0.85);
}

h1 {
  /* font-size: clamp(1.2rem, 5vw, 2rem); */
  font-size: 2rem;
}

h2 {
  /* font-size: clamp(1rem, 4vw, 1.4rem); */
  font-size: 1.4rem;
}

p {
  /* font-size: clamp(0.6rem, 3vw, 1rem); */
  font-size: 1rem;
}

.page {
  padding: 15px;
}

.profil-image {
  border-radius: 500px;
  border-style: solid;
  border-width: 3px;
  border-color: var(--background-color-secondary);
}

[data-tooltip]:before {
  /* needed - do not touch */
  content: attr(data-tooltip);
  position: absolute;
  opacity: 0;
  transition: 0s opacity;
  font-size: 12px;

  /* customizable */
  color: white;
  border-radius: 10px;
}

[data-tooltip]:hover:before {
  /* needed - do not touch */
  opacity: 1;
  transition-delay: 0.5s;

  /* customizable */
  background: grey;
  padding: 3px 7px;
  margin-top: -50px;
}
[data-tooltip]:hover:before:empty {
  display: none !important;
  padding: 0px !important;
}

[data-tooltip]:not([data-tooltip-persistent]):before {
  pointer-events: none;
}

@media screen and (max-width: 600px) {
  *:focus {
    border: none;
  }
  .clickable:focus {
    border-width: 0px;
  }
  .clickable:hover {
    filter: brightness(1);
  }
}
@media (hover: none) and (pointer: coarse) {
  *:focus {
    border: none;
  }
  .clickable:focus {
    border-width: 0px;
  }
  .clickable:hover {
    filter: brightness(1);
  }
}
