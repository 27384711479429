.rounded-label {
  height: 75px;
  width: 75px;
  background-color: var(--highlight-color-primary);
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  /* color: var(--background-color-primary); */
  color: white;
  margin: 5px;
  padding-top: -5px;
}
.rounded-label p {
  margin: 0px;
  padding: 0px;
  text-align: center;
}
.rounded-label p.unit {
  word-wrap: break-word;
  margin-top: -5px;
  font-size: 12px;
}
.rounded-label div.unit {
  background-color: transparent;
  margin-top: -5px;
  height: 18px;
}
.rounded-label div {
  width: 60%;
  margin: 0px 10px;
  /* background-color: var(--background-color-primary); */
  background-color: white;
  height: 1px;
}
