.rounded-label > input {
  background-color: transparent;
  width: 70%;
  text-align: center;
  font-size: 12px;
  color: white;
  padding: 0px;
  margin: 2px;
  border: 1px solid white;
  border-radius: 5px;
}
.rounded-label > input::placeholder {
  color: rgb(220, 220, 220);
  font-size: 10px;
  font-style: italic;
}
.rounded-label .unit.edit {
  margin-top: 2px;
}
input[type='number']::-webkit-inner-spin-button {
  opacity: 1;
  cursor: pointer;
  margin: 0.5px;
}
