div.flex {
  display: flex;
}

.main {
  flex-direction: row;
  flex: 1;
  height: 100vh;
  overflow: hidden;
}

.timer-panel {
  position: absolute;
  left: 0px;
  bottom: 0px;
  flex-wrap: wrap;
}
#demo-badged {
  z-index: 200;
  color: white;
  justify-content: center;
  padding: 5px 50px;
  position: absolute;
  right: -50px;
  top: 35px;
  background-color: #f60;
  transform: rotate(45deg);
  -webkit-box-shadow: 1px 7px 14px -8px #000;
  -moz-box-shadow: 1px 7px 14px -8px #000;
  box-shadow: 1px 7px 14px -8px #000;
}

@media screen and (max-width: 600px) {
  .main {
    flex-direction: column-reverse;
  }
  .main-container {
    display: block;
    height: auto;
    overflow: auto;
    margin-bottom: 60px;
  }
}
